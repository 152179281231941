import qs from "qs";

const swrConfigurations = {
  getLayoutSharedData: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/pages/layout-shared-data${urlQuery}`),
      refreshWhenOffline: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      revalidateOnFocus: false,
      /*
        INFO: The refresh interval is set to 1 second. This ensures that the latest data is fetched 
        shortly after the page loads, even if initial data is already available. Additionally, 
        a deduplication interval of 5 minutes is configured to prevent redundant data fetching.
      */
      refreshInterval: 1000 * 1,
      dedupingInterval: 1000 * 300, 
      ...config,
    };
  },

  getPropsPageSection: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        path: query?.path,
        id: query?.id,
        locale: query?.locale || "en",
        section: query?.section,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/page/get-props-per-section${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshInterval: 60000 * 30,
      ...config,
    };
  },
  // `http://localhost:3001/api/page/get-props-per-section?path=/&id=10&locale=en&section=${
  //     args?.id ? args.id : dataSample.id
  //   }`
  getInstruments: ({ query = "" }, config = {}) => {
    const urlQuery = qs.stringify(
      {
        key: 1,
        q: query,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`https://api-v2.capex.com/quotesv2${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 100,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getNavigation: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/navigations/navigation${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 1000 * 30 * 60,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getExpirationDates: (query = {}, config = {}) => {
    const urlQuery = qs.stringify({
      filters: {
        type: 'rollover_expiration_dates'
      }
    }, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/dealing-datas${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 1000 * 30 * 60,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getDealingDataMT5: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/dealing-data${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 1000 * 30 * 60,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },

  getDealingData: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        key: 1,
        q: query.instrument,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`https://api-v2.capex.com/dealingdata${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 1000 * 30 * 60,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getDataGlobal: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/dataglobals/data${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 1000 * 30 * 60,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getProductTypes: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        ...query,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/product-types/get-all${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 1000 * 30 * 60,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getGainersAndLosers: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        key: 1,
        q: query,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`https://api-v2.capex.com/quotesv2${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 100,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getInstrumentPerformance: (query = {}, config = {}) => {
    // query :
    // limit : integer
    // type : String;
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/instruments/performance${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshInterval: 60000 * 30,
      ...config,
    };
  },
  getDealingDataPlatformDMA: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/dealing-datas${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshInterval: 60000 * 30,
      ...config,
    };
  },
  getDealingDataPlatformMT5: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/dealingdata/data${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshInterval: 60000 * 30,
      ...config,
    };
  },
  getGlobalVariableSwr: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        ...query,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/json/global-variables.json${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: false,
      refreshInterval: 60000 * 30,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getInstrumentChart: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        symbols: query.instrument,
        fromDate: query.fromDate,
        toDate: query.toDate,
        eventType: 'Candle',
        candleType: query.timeframe,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`https://market-api.capex.com/marketdata${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 60000,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
      ...config,
    };
  },
  instrumentSentiment: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        key: 1,
        brand: "cfdglobal",
        q: query.instrument,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`https://api-v2.capex.com/sentiment${urlQuery}`),
      refreshWhenOffline: false,
      refreshInterval: 10000,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getLegalDocumentsByCategory: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        ...query,
        populate: "all",
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/legal-documents${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getNestedPageUrl: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        fields: ["alternative_page_slug"],
        populate: {
          global_pages: {
            fields: ["slug"],
          },
        },
        ...query,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/nestedpages${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  searchInstruments: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        populate: "*",
        fields: ["api_id", "display_name"],
        filters: {
          $or: [
            {
              display_name: {
                $contains: query.searchValue,
              },
            },
            {
              api_id: {
                $contains: query.searchValue,
              },
            },
          ],
          $and: [
            {
              ...(query?.filter?.category && {
                global_instrument_types: {
                  name: {
                    $contains: query.filter.category,
                  },
                },
              }),
            },
          ],
        },
        sort: ["publishedAt:desc"],
        locale: query.locale || "en",
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/instruments${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: true,
      revalidateOnFocus: false,
      refreshInterval: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getInstrumentData: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/instruments${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: true,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getInstrumentCategories: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/instrumenttypes${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: true,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  homeslides: ({ query = "" }, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/home-slides${urlQuery}`),
      refreshWhenOffline: false,
      revalidateFirstPage: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      revalidateOnMount: false,
      refreshWhenHidden: false,
      ...config,
    };
  },

  getNestedPage: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/nestedpages${urlQuery}`),
      refreshWhenOffline: false,
      revalidateFirstPage: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshInterval: 100,
      revalidateOnMount: false,
      refreshWhenHidden: false,
      ...config,
    };
  },

  modals: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/modals${urlQuery}`),
      refreshWhenOffline: false,
      revalidateFirstPage: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      refreshInterval: 60000 * 30,
      revalidateOnMount: false,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getBreadcrumb: (query = {}, config = {}) => {
    //requires Collection API to get the artic.e/instrument Id
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/${query.collectionAPI}${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...config,
    };
  },
  getArticles: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/articles${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...config,
    };
  },
  getAcademySubjects: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/academy-subjects${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...config,
    };
  },
  getWebsiteLanguages: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        ...query,
        locale: "all",
        populate: {
          flag: "*",
        },
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/languages${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshInterval: 1000 * 30 * 60,
      ...config,
    };
  },
  getArticleWithInstruments: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/articles-with-instruments${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...config,
    };
  },

  getNextArticle: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        sort: {
          publishedAt: "asc",
        },
        pagination: {
          pageSize: 1,
        },
        ...query,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/articles${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...config,
    };
  },
  getPreviousArticle: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(
      {
        sort: {
          publishedAt: "desc",
        },
        pagination: {
          pageSize: 1,
        },
        ...query,
      },
      {
        addQueryPrefix: true,
      }
    );
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/articles${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...config,
    };
  },
  pressRoomCards: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query);
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/presses?${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  testimonials: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query);
    return {
      url: `${process.env.NEXT_PUBLIC_API_STRAPI}/api/testimonials?${urlQuery}`,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  ipo: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/ipos${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
      ...config,
    };
  },
  financialDictionary: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/financial-dictionaries${urlQuery}`),
      refreshWhenOffline: false,
      revalidateOnReconnect: true,
      refreshWhenHidden: false,
      ...config,
    };
  },
  getPagesUrls: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/pages${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 1000 * 30 * 60,
      ...config,
    };
  },
  getWebinars: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/webinars${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  getUpcomingWebinars: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/webinars${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  awards: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/awards${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  academyCategories: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/academy-categs${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  IntegratedToolsNavigation: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/integrated-tools-navigations${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },

  sidebarSections: (query = {}, config = {}) => {
    const articleType = query.article_type;
    return {
      url: decodeURIComponent(
        `${process.env.NEXT_PUBLIC_API_STRAPI}/api/articles?filters[articles_category][name]=${articleType}&populate[sidebar_section][populate][related_page][slug]=*`
      ),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  officeLocations: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/contact-us-locations${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },

  careersLocations: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query);
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/careers-locations?${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  careersDepartments: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query);
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/careers-departments?${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  careersJobs: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query);
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/careers-jobs?${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  paymentMethods: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/payment-methods/methods${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
  regulators: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query);
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/regulators?${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },

  podcastsPlatforms: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query);
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/podcast-platforms?${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },

  podcastsEpisodes: (query = {}, config = {}) => {
    const urlQuery = qs.stringify(query, {
      addQueryPrefix: true,
    });
    return {
      url: decodeURIComponent(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/podcast-episodes${urlQuery}`),
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...config,
    };
  },
};

export default function _swrConfig(api) {
  return swrConfigurations[api];
}
